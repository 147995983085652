import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Button from "../../Button"
import ImagesService from '../../../utils/images-service'

import "./styles.scss"


class Hero extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        let hasMobile = false

        if (typeof this.blok.mobile_image !== "undefined") {
            hasMobile = true
        }


        if (this.blok.type === "full") {
            return (
                <SbEditable content={this.blok}>
                    <div className="hero-block-fullscreen">
                        {hasMobile &&
                        <div>
                            <div className="container desktop" style={{ backgroundImage: `url(${ ImagesService(this.blok.image.filename, true, { path: '1920x1080/smart'})})`}}>
                                <div className="text">
                                    <h1>{this.blok.headline}</h1>
                                    <p>{this.blok.teaser}</p>
                                    <div className="buttons">
                                        {this.blok.buttons.map((button, i) => {
                                            return (<Button key={i} type={button.type} to={button.url} label={button.label} title={button.label} />)
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="container mobile" style={{ backgroundImage: `url(${ ImagesService(this.blok.mobile_image.filename, true, { path: '1080x1920/smart'})})`}}>
                                <div className="text">
                                    <h1>{this.blok.headline}</h1>
                                    <p>{this.blok.teaser}</p>
                                    <div className="buttons">
                                        {this.blok.buttons.map((button, i) => {
                                            return (<Button key={i} type={button.type} to={button.url} label={button.label} title={button.label} />)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {!hasMobile &&
                        <div>
                            <div className="container desktop" style={{ backgroundImage: `url(${ ImagesService(this.blok.image.filename, true, { path: '1920x1080/smart'})})`}}>
                                <div className="text">
                                    <h1>{this.blok.headline}</h1>
                                    <p>{this.blok.teaser}</p>
                                    <div className="buttons">
                                        {this.blok.buttons.map((button, i) => {
                                            return (<Button key={i} type={button.type} to={button.url} label={button.label} title={button.label} />)
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="container mobile" style={{ backgroundImage: `url(${ ImagesService(this.blok.image.filename, true, { path: '1080x1920/smart'})})`}}>
                            <div className="text">
                                <h1>{this.blok.headline}</h1>
                                <p>{this.blok.teaser}</p>
                                <div className="buttons">
                                    {this.blok.buttons.map((button, i) => {
                                        return (<Button key={i} type={button.type} to={button.url} label={button.label} title={button.label} />)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                        }
                    </div>
                </SbEditable>
            )
        } else {
            return (
                <SbEditable content={this.blok}>
                    <div className="hero-block-splitscreen">
                        <div className="container">
                            <div className="text">
                                <h1>{this.blok.headline}</h1>
                                <p>{this.blok.teaser}</p>
                                <div className="buttons">
                                    {this.blok.buttons.map((button, i) => {
                                        return (<Button key={i} type={button.type} to={button.url} label={button.label} title={button.label} />)
                                    })}
                                </div>
                            </div>
                            {this.blok.image.filename !== "" &&
                            <div className="image" style={{backgroundImage: `url(${ ImagesService(this.blok.image.filename, true, { path: '900x600/smart'}) })`}}>
                                {hasMobile &&
                                    <img src={ImagesService(this.blok.image.filename, true, { path: '450x338/smart'})} srcSet={`${ImagesService(this.blok.image.filename, true, { path: '450x338/smart'})} 1x, ${ImagesService(this.blok.mobile_image.filename, true, { path: '900x676/smart'})} 2x`} width="450" height="338" />
                                }
                                {!hasMobile &&
                                    <img src={ImagesService(this.blok.image.filename, true, { path: '450x338/smart'})} srcSet={`${ImagesService(this.blok.image.filename, true, { path: '450x338/smart'})} 1x, ${ImagesService(this.blok.image.filename, true, { path: '900x676/smart'})} 2x`} width="450" height="338" />
                                }
                            </div>
                            }
                        </div>
                    </div>
                </SbEditable>
            )
        }
    }
}  
  
export default Hero