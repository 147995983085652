import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, locales, structuredData, prefix }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const openGraphLocales = generateOpenGraphLocales(locales)

  return (
    <Helmet
      htmlAttributes={{
        lang,
        prefix
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@conducolabs`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta).concat(openGraphLocales)}
    >
      {locales.map((locale) => {
        return <link rel="alternate" href={"https://condu.co/" + locale.lang + "/" + locale.path} hrefLang={locale.lang} key={locale.lang} />
      })}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
  locales: [],
  prefix: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  og: PropTypes.object,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  locales: PropTypes.array,
  prefix: PropTypes.string
}


function generateOpenGraphLocales(locales) {
    const output = []

    for(let locale of locales) {
      output.push({
        name: `og:locale:alternate`,
        content: locale.lang
      })
    }

    return output
}

export default SEO