import React, { Component } from "react"
import Link from "gatsby-link"
import SbEditable from "storyblok-react"
import Button from "../Button/index"

import "./styles.scss"

import { withTranslation } from "react-i18next"

class Navigation extends Component {
    constructor(props) {
        super(props)
        this.t = props.t
        this.settings = props.settings
        this.location = props.location
        this.state = {
            isOpen: "not-open"
        }

        this.path = null

        if (typeof props.location !== "undefined") {
            const folders = props.location.pathname.split("/")
            this.path = `${ folders[1] }/${ folders[2] }`
        }

        this.toggleMenu = this.toggleMenu.bind(this)
    }

    toggleMenu() {
        if (this.state.isOpen === "not-open") {
            this.setState({ isOpen: "is-open" })
        } else {
            this.setState({ isOpen: "not-open" })
        }
    }

    render() {
        return (
            <div className="navigation-wrapper">
                <nav className={`main-navigation ${ this.state.isOpen }`} role="navigation" aria-label={this.t("header.navigation.label")}>
                    <div className="close-trigger" onClick={this.toggleMenu} role="button" onKeyDown={this.toggleMenu} tabIndex={0}>{this.t("header.navigation.overlayClose")}</div>
                    <ul>
                    {this.settings && this.settings.content.main_navi.map((navitem, index) => (
                        <SbEditable content={navitem} key={navitem._uid}>
                            <li key={index} className={navitem.classes}>
                                {navitem.link.cached_url === this.path &&
                                <Link className="active" to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                {navitem.name}
                                </Link>
                                }
                                {navitem.link.cached_url !== this.path &&
                                <Link to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                {navitem.name}
                                </Link>
                                }
                                {navitem.subitems.length > 0 &&
                                    <ul className="sublevel">
                                        {navitem.subitems.map((subitem, subindex) => (
                                            <li key={subindex}>
                                                {subitem.link.cached_url === this.path &&
                                                <Link className="active" to={`/${subitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                                {subitem.name}
                                                </Link>
                                                }
                                                {subitem.link.cached_url !== this.path &&
                                                <Link to={`/${subitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                                {subitem.name}
                                                </Link>
                                                } 
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </li>
                        </SbEditable>
                    ))}
                    </ul>
                    <Button type="primary" to={`/${this.settings.navi_button_link.cached_url }`} label={this.t("header.button.label")} title={this.t("header.button.title")} />   
                </nav>
                <div className={`menu-toggle ${ this.state.isOpen }`} onClick={this.toggleMenu} role="button" onKeyDown={this.toggleMenu} tabIndex={0}>
                    <div className="line-one line"></div>
                    <div className="line-two line"></div>
                    <div className="line-three line"></div>
                </div>
            </div>
          )
    }
}

export default withTranslation()(Navigation)