import Link from 'gatsby-link'
import React, { Component } from "react"
import logoImage from "../../images/asamoah-stiftung-logo.svg"

import "./styles.scss"

import { withTranslation } from "react-i18next"

class Logo extends Component {
    constructor(props) {
        super(props)
        this.t = props.t
        this.lang = props.lang
    }

    render() {
        return (
            <div className="logo">
                <Link to={`/${this.lang}`} title={this.t("logo.title")}><img src={logoImage} alt="Gerald Asamoah Stiftung" width="59" height="39" /></Link>
            </div>
        )
    }
}  
  
export default withTranslation()(Logo)